
html {
    scroll-behavior: smooth;
  }
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@keyframes moveInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  80% {
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  80% {
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.navbar {
    background: var(--white);
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    z-index: 2;
    /* box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.15); */
    box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.15);
  }

  .navbar img {
    width: clamp(125px, 30vw, 200px);
    margin-bottom: -2px;
  }
  .nav-center {
    width: 100%;
    max-width: var(--max-width);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav-center a {
    font-size: clamp(1rem, 40vw, 0.9rem);
    color: var(--grey-1100);
    transition: var(--transition);
    text-transform: uppercase;
    font-family: 'Lato', sans-serif;
    /* font-family: 'Montserrat', sans-serif; */
/* font-family: 'Roboto', sans-serif; */
  }
  .nav-center a:hover {
    color: var(--primary-500);
  }
  .nav-center a:not(:last-of-type) {
    margin-right: 0.25rem;
  }
  
  .home,
  .about,
  .projects,
  .contact,
  .rest {
    min-height: 60vh;
    text-align: center;
  }
  
  .home {
    /* background: #BAEDAF; */
    /* background: #6784EB; */
    /* background: #EB967F; */
    /* background: #67EBC4; */
    /* background: #187056; */
    /* background: #31BD94; */
    /* background: #16D96C; */
    /* background: #0BD7D9; */
    /* background: #93DFC8; */
    background: #bee5bf;
  }
  .about {
    background: #dff3e3;
    /* background: #dff3e3; */
  }
  .projects {
    /* background: #28BF92; */
    background: #bee5bf;
  }
  .contact {
    background: #dff3e3;
    /* background: #dff3e3; */
  }
  
  .sticky {
    position: fixed;
    top: 0;
  }

  #app {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  }
  
  body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  }
  
  body {
    background: #fff;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  }
  
  html,
  body {
    position: relative;
    height: 100%;
  }
  
  #app {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .mySwiper {
    width: 412px;
    height: 550px;
    padding-bottom: 33px;
  }

  @media (max-width: 767px) {
    .mySwiper {
      width: 333px;
      height: 444px;
      padding-bottom: 33px;
    }
  }
  
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }
  
  .swiper-slide:nth-child(1n) {
    background-color: #047084;
  }
  
  .swiper-slide:nth-child(2n) {
    background-color: #1DA1F2;
  }
  
  .swiper-slide:nth-child(3n) {
    background-color: rgb(10, 184, 111);
  }
  
  .swiper-slide:nth-child(4n) {
    background-color: #9EFB5D;
  }
  
  .swiper-slide:nth-child(5n) {
    background-color: rgb(118, 163, 12);
  }
  
  .swiper-slide:nth-child(6n) {
    background-color: rgb(180, 10, 47);
  }
  
  .swiper-slide:nth-child(7n) {
    background-color: rgb(35, 99, 19);
  }
  
  .swiper-slide:nth-child(8n) {
    background-color: rgb(0, 68, 255);
  }
  
  .swiper-slide:nth-child(9n) {
    background-color: rgb(218, 12, 218);
  }
  
  .swiper-slide:nth-child(10n) {
    background-color: rgb(54, 94, 77);
  }
.project-carousel {
    margin-top: 30px;
    /* height: 500px; */
    /* z-index: 1; */
}  
.contact-form {
    padding-top: 27px;
    padding-bottom: 50px;
}

.footer-icon-images {
  height:50px;
  width:50px;
  padding:5px;
}

#footer {
  height:55px;
  text-align: center;
}

.footer-container {
  padding-top: 10px;
}

#landing-canvas {
  width: 100%;
  height: 100%;
  /* text-align: center; */
  /* position: absolute; */
  /* top: 0; */
  /* pointer-events: none; */
  overflow: hidden;
  /* padding:10px; */
}

.landing-container {
  padding: 0px !important;
}

.page-content {
  padding-top: 22px;
  padding-bottom: 44px;
  overflow: hidden;
}

#about-text {
  /* display:flex; */
  text-align: left;
  font-size: 20px;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
}

#left-profile-photo {
  display:flex;
  align-items: center; 
  /* /* justify-content: center; */
  padding: 40px;
  margin:40px;
  padding-top: 44px;
  animation-name: moveInFromRight;
  animation-duration: 5s;
}

.profile-photo-div {
  display:flex;
  align-items: center; 
  /* /* justify-content: center; */
  padding: 40px;
  margin:40px;
  padding-top: 44px;
  animation-name: moveInFromLeft;
  animation-duration: 5s;
  /* margin: auto; */
}
.still-profile-photo-div {
  display:flex;
  align-items: center;
  padding: 10px;
  padding-top: 20px;
  /* animation-name: moveInFromLeft;
  animation-duration: 5s; */
  margin: auto;
}
.small-profile-photo-div {
  display:flex;
  align-items: center;
  padding: 10px;
  animation-name: moveInFromLeft;
  animation-duration: 5s;
  /* margin: auto; */
}
.still-small-profile-photo-div {
  display:flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  /* margin: auto; */
}

.empty-container{
  height:300px;
}

.profile-image {
  height:400px;
  width:auto;
  border-radius: 10px;
  text-align: center;
}

#small-profile-image{
  height: 300px;
}

#language-container {
  padding-top: 50px;
}
#language-title{
  padding-bottom: 5px;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 700;
}

.home {
  padding-top: 33px;
  padding-bottom: 33px;
}

#projects {
  height: 777px;
}
@media (max-width: 767px) {
  #projects {
    height: 666px;
  }
}

.projects-gif{
  text-align: "center";
    /* // minHeight: 250;
    // minWidth: 250;
    // maxHeight: 300;
    // maxWidth: 300; */
    height: 250px;
    width: 250px;
    margin-top: 20px;
    margin-left: 40px;
    /* // marginLeft: auto;
    // marginRight: auto; */
    border: 1px solid #F0f0f7;
    border-radius: 5;
}

.card-actions {
  /* text-align: center important!; */
  justify-content: center;
}
.card-content {
  padding-top: 5px;
  padding-left: 7px;
  padding-right: 7px;
}

#content-title {
   
}
#content-body {

}

.project-button {
  margin: 5px !important;
}
.MuiButtonBase-root{
  position: static !important;
}

#contact { 
  padding-bottom: -40px !important;
}

#about{
  /* height:600px  */
  padding-bottom: 50px;
}

.page-title{
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 40px;
  padding-bottom: 33px;
}

.nav-link{
  text-decoration: none;
  padding-right: 7px;
}

#navbar {
  height: 66px;
  width: 100%;
  /* background-color: #28BF92; */
  background-color: #bee5bf;
}
#scale {
  height: 110px;
  /* width:auto; */
  margin-bottom: -5px;
}
.ten {
  height:100px;
  
}
.nine {
  height:90px;
  
}
.eight {
  height:80px;
  
}
.seven {
  height:70px;
  
}
.six {
  height:60px;
  
}
.five {
  height:50px;
  
}
.four {
  height:40px;
  
}
.three {
  height:30px;
  
}
.two {
  height:20px;
  
}
.one {
  height:10px;
  
}
 
.lato {
  font-family: 'Lato', sans-serif !important;
}

.montserrat {
  font-family: 'Montserrat', sans-serif !important;
}
/* font-family: 'Lato', sans-serif; */
/* font-family: 'Montserrat', sans-serif; */
/* font-family: 'Roboto', sans-serif; */
